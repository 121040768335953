@function getVw($value, $width) {
  @return $value / $width * 100 + 0vw;
}

@function getCl($valueCl, $widthCl, $maxCl){
  // font-size: getCl(14,375,18);
   $minWidth:375;
   $valueClSize:$valueCl*.1rem;
   $maxClSize:$maxCl*.1rem;
   $minWidthSize:round($valueCl / $widthCl *100*1000)/1000;
   @return unquote("clamp(#{$valueClSize}, #{$minWidthSize}vw, #{$maxClSize})");;
 }
 @function clampVw($min, $size, $max, $vp: 1920) {
  //  font-size: clampVw(14, 16, 18, 768);
  $min: $min * 0.1rem;
  $max: $max * 0.1rem;
  $num: 100 / $vp * 1vw;
  $size_vw: $num * $size;
@return clamp($min, $size_vw, $max);
}

 
 @function MinWidthSize($widthValue){
  /*そのサイトで変動させたい最小の画面ビューポートのサイズ*/
   $minWidth:1440;
 /*そのサイトで変動させたい最大の画面ビューポートのサイズ*/
   $maxWidth:1440;
 /*pxの単位を付ける*/
   $minWidthSize:$widthValue*.1rem;
 /*最小のフォントサイズとビューポートのサイズを使って、vwを求める
 　値は小数点第３位までで四捨五入する*/
   $widthVw:round($widthValue/$minWidth*100*1000)/1000;
 /*求めた値を使って、clamp()関数を返す*/
   @return unquote("min(#{$widthVw}vw,#{$minWidthSize})");
 }

@function MaxWidthSize($widthValue){
  /*そのサイトで変動させたい最小の画面ビューポートのサイズ*/
   $minWidth:1440;
 /*そのサイトで変動させたい最大の画面ビューポートのサイズ*/
   $maxWidth:1440;
 /*pxの単位を付ける*/
   $minWidthSize:$widthValue*.1rem;
 /*最小のフォントサイズとビューポートのサイズを使って、vwを求める
 　値は小数点第３位までで四捨五入する*/
   $widthVw:round($widthValue/$minWidth*100*1000)/1000;
 /*求めた値を使って、clamp()関数を返す*/
   @return unquote("max(#{$widthVw}vw,#{$minWidthSize})");
 }


@function cssLock($maxSize, $minSize, $maxWidth, $minWidth) {
  @return calc(#{getVw(($maxSize - $minSize), ($maxWidth - $minWidth)) } + (#{$maxSize / 10}rem - #{$maxWidth / 10}rem * #{(($maxSize - $minSize) /  ($maxWidth - $minWidth))}));
}
