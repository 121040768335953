.u-pc_show {
  display: block;
  @media #{$breakpoint} {
    display: none;
  }
}

.u-sp_show {
  display: none;
  @media #{$breakpoint} {
    display: block;
  }
}

.u-tab_none {
  @media #{$breakpointtab} {
    display: none;
    @media #{$breakpoint} {
      display: block;
    }
  }
}

.noscroll {
  overflow: hidden !important;
  height: 100vh;
}

.u-bg_gray {
  background: #f6f6f6;
}


.js-hover_btn {
  pointer-events: auto;
  &.is-event_none {
    pointer-events: none;
  }
}

.js-more_txt {
  &_right {
    display: inline-block;
  }
}

.js-slick_service_control {
  font-size: 0;
}

.js-overlay {
  position: relative;
  &::before {
    visibility: hidden;
    background: #000000;
    opacity: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    transition:visibility 0.4s, opacity 0.4s;
    display: block;
    content: "";
    position: fixed;
  }
  &.is-active {
    &::before {
      visibility: visible;
      opacity: .9;
    }
  }
}
// .js-scroll {
//   &_item {
//     transform: translateY(30%);
//   }
// }

.js-txt {
  &_anime {
    // transform: translateX(100%);
    opacity: 1;
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      width: 0%;
      height: MinWidthSize(42);
      background: #000000;
      transform: translateX(-100%);
      transition: width 0.4s,transform 0.4s;
      @media #{$breakpoint}{
        height: getVw(30,$widthSp);
      }
    }
    &.is-active {
      &::before {
        width: 100%;
        transform: translateX(0%);
      }
    }
    &_inner {
      transform: translateX(-100%);
      opacity: 0;
    }
  }
}

// .js-black {
//     position: absolute;
//     left: 0;
//     width: 0%;
//     height: 2.4rem;
//     background: #000000;
//     transform: translateX(-100%);

// }
.js-scroll_item {
  // transform: translate3d(0,0,0);
  will-change: transform;
  backface-visibility:hidden;
}

.js-accordion_open {
  display: none;
}

.js-accordion_open.active {
  display: block;
}

.js-mega_menu {
  display: none;
  &_link {
    &.is-active {
      .js-mega_menu_link_border {
        position: relative;
        &::after {
          display: block;
          content: "";
          bottom: 0;
          width: 100%;
          height: 1px;
          background: #222222;
        }
        // border-bottom: 1px solid #222222;
      }
    }
  }
}

.js-body {
  &::before {
    display: block;
    content: "";
    transition: opacity .4s,visibility .4s;
    visibility: hidden;
    opacity: 0;
  }
  &.is-active {
    &::before {
      visibility: visible;
      display: block;
      content: "";
      top: 0;
      z-index: 4;
      left: 0;
      height: 100%;
      min-height: 100vh;
      width: 100%;
      position: fixed;
      background: #222222;
      opacity: .6;
    }
  }
}




@for $i from 0 through 50 {
  .u-mt_#{4 * $i} {
    // margin-top: #{.4 * $i}rem;
    margin-top: MinWidthSize(4 * $i);
  }
}
@for $i from 0 through 50 {
  .u-ml_#{4 * $i} {
    margin-left: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-mb_#{4 * $i} {
    // margin-bottom: #{.4 * $i}rem;
    margin-bottom: MinWidthSize(4 * $i);
  }
}
@for $i from 0 through 50 {
  .u-pt_#{4 * $i} {
    padding-top: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pl_#{4 * $i} {
    padding-left: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pb_#{4 * $i} {
    padding-bottom: #{.4 * $i}rem;
  }
}
@for $i from 0 through 50 {
  .u-pr_#{4 * $i} {
    padding-right: #{.4 * $i}rem;
  }
}

@for $i from 0 through 50 {
  .u-sp_mt_#{4 * $i} {
    @media #{$breakpoint} {
      margin-top: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_ml_#{4 * $i} {
    @media #{$breakpoint} {
      margin-left: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_mb_#{4 * $i} {
    @media #{$breakpoint} {
      margin-bottom: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pt_#{4 * $i} {
    @media #{$breakpoint} {
      padding-top: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pl_#{4 * $i} {
    @media #{$breakpoint} {
      padding-left: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pb_#{4 * $i} {
    @media #{$breakpoint} {
      padding-bottom: #{.4 * $i}rem;
    }
  }
}
@for $i from 0 through 50 {
  .u-sp_pr_#{4 * $i} {
    @media #{$breakpoint} {
      padding-right: #{.4 * $i}rem;
    }
  }
}
