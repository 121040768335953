$widthSp: 375;
$widthPc: 1440;

.c-alt {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}



.c-ttl {
  &_large {
    line-height: (96/40);
    color: #222222;
    @include NotoSansBold;
    font-size:getCl(16,$widthSp,20);
    letter-spacing: .08em;
    @media #{$breakpoint}{
      line-height: (72/32); 
    }
  }
  &_middle {
    // line-height: (96/32);
    color: #222222;
    @include NotoSansBold;
    font-size:1.6rem;
    letter-spacing: .08em;
  }
  &_en {
    @include MontoBold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
    background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
    &_middle {
      @include Monto;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: getCl(28,$widthSp,60);
      font-weight: 700;
      background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
    }
    &_large {
      @include MontoBold;
      font-size:getCl(32,$widthSp,80);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
    }
  }
}

.c-txt {
  &_middle {
    font-size: getCl(14,$widthSp,16);
    line-height: (48/32);
    @media #{$breakpoint}{
      line-height: (48/28); 
    }
    
  }
}

.c-time {
  &_txt {
    @include MontoBold;
    font-size: 1.4rem;
    line-height: (48/28);
    color: #444444;
  }
}

.c-number {
  &_01 {
    &::before {
      font-size: 10rem;
      line-height: (200/86);
      letter-spacing: 0;
      @include  barlowBold;
      color: $mainColor;
    }
  }
}

.c-btn  {
  &_more {
    position: relative;
    display: block;
    --borderTopScaleX: 1;
    --borderRotate:0;
    --backGround:90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%;
    // --backGroundWhite:90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%;
    --backGroundWhite:transparent;
    --backGroundTrans:transparent;
    --borderWhite:1px solid #ffffff;
    --arrowWhite:url(/img/common/c-btn_arrow_white.svg);
    --arrowBlue:url(/img/common/c-btn_arrow_blue.svg);
    text-align: left;
    min-width: 19rem;
    width: 19rem;
    @media #{$breakpoint}{
      min-width: 3.5rem;
      width: 3.5rem;
      margin-top: 0;
    }
    &::before {
      @include elementImg;
      // position: inherit;
      top: 50%;
      z-index: 1;
      // right: .8rem;
      right: 1.8rem;
      transform: translateY(-50%);
      background: url(/img/common/c-btn_arrow_blue.svg);
      // width: 2rem;
      // height: 1.4rem;
      width: 2.4rem;
      height: 1.8rem;
      @media #{$breakpoint}{
        right: 1.3rem; 
      }
    }
    &.is-white {
      min-width: 16.5rem;
      width: 16.5rem;
      @media #{$breakpoint}{
        min-width: 3.5rem;
        width: 3.5rem; 
      }
      &::before {
        background: var(--arrowWhite);
        @include elementImg;
        // position: inherit;
        top: 50%;
        z-index: 1;
        // right: .6rem;
        transform: translateY(-50%);
        // width: 2.5rem;
        width: 2rem;
        right: .8rem;
        height: 1.4rem;
        @media #{$breakpoint}{
          right: 0.8rem;
          width: 1.4rem;
          height: 1rem; 
        }
      }
    }
    &.is-arrow_blue {
      &::before {
        @include elementImg;
        // position: inherit;
        top: 50%;
        z-index: 1;
        right: .3rem;
        transform: translateY(-50%);
        width: 2.5rem;
        height: 1.4rem;
        background: var(--arrowBlue);
      }
    }
    &.is-mail {
      &::before {
        @include elementImg;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
        // right: 0.7rem;
        // width: 2rem;
        // height: 1.4rem;
        right: 1.6rem;
        width: 4rem;
        height: 2.4rem;
        background: url(#{$imgPath}common/c-btn_mail.svg);
      }
    }
    &.is-right {
      margin: 0 0 0 auto;
    }
    &_txt {
      @include Monto;
      margin: 0 -.2rem;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 2rem;
      --borderTopScaleX: 1;
      background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
      &::before {
        display: block;
        position: absolute;
        content: "";
        right: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%) scale(var(--borderTopScaleX)) rotate(var(--borderRotate));
        // width: 3.6rem;
        // height: 3.6rem;
        width: 5.6rem;
        height: 5.6rem;
        background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        // background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(255,255,255) 50%);
        background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        // background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        background-image: -webkit-linear-gradient(var(--backGround));
        // background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        @media #{$breakpoint}{
          width: 4.6rem;
          height: 4.6rem; 
        }
      }
      
      &::after {
        display: block;
        position: absolute;
        content: "";
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%) scale(var(--borderTopScaleX));
        // right: 2px;
        // width: 3.2rem;
        // height: 3.2rem;
        right: 1px;
        // width: 3.4rem;
        // height: 3.4rem;
        width: 5.4rem;
        height: 5.4rem;
        background: #ffffff;
        @media #{$breakpoint}{
          width: 4.4rem;
          height: 4.4rem; 
        }
      }
      &.is-white {
        font-size: 1.6rem;
        &::before {
          display: block;
          position: absolute;
          content: "";
          right: 0;
          border-radius: 50%;
          top: 50%;
          transform: translateY(-50%) scale(var(--borderTopScaleX)) rotate(var(--borderRotate));
          width: 3.6rem;
          height: 3.6rem;
          background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
          // background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(255,255,255) 50%);
          background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
          // background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
          background-image: -webkit-linear-gradient(var(--backGroundWhite));
          border: var(--borderWhite);
          @media #{$breakpoint}{
            width: 3rem;
            height: 3rem; 
          }
        }
        &::after {
          background: var(--backGroundTrans);
        }
      }
      &.is-gray {
        &::after {
          background: #f6f6f6;
        }
      }
      &_parents {
        overflow: hidden;
        display: block;
        @media #{$breakpoint}{
          display: none; 
        }
        &.is-left {
          text-align: left;
        }
        &.is-shadow {
          line-height: 1.5;
        }
        &.is-en {
          &_black {
            padding-left: .2rem;
            // width: 103%;
            width: calc(100% + 0.6rem);
            line-height: 1.3;
            color: #222222;
          }
          &_white {
            padding-left: .2rem;
            // width: 103%;
            width: calc(100% + .6rem);
            line-height: 1.3;
            color: #ffffff;
          }
          &_gray {
            padding-left: .2rem;
            display: inline-block;
            line-height: 1.3;
            color: #666666;
            width: calc(100% + .2rem);
          }
        }
        &.is-back_archive  {
          padding-left: .2rem;
          display: inline-block;
          line-height: 1.3;
        }
        &.is-back_top  {
          padding-left: .2rem;
          display: inline-block;
          line-height: 1.3;
        }
        &.is-jp {
          &_gray {
            padding-left: .2rem;
            display: inline-block;
            line-height: 1.3;
            color: #666666;
          }
        }
        &.is-contact {
          width: 100%;
          padding-left: 0;
        }
        &.is-contact_btn {
          width: 103%;
        }
        &.is-header {
          @media #{$breakpoint}{
            display: block; 
          }
        }
        &.is-contact {
          @media #{$breakpointtab}{
            display: none; 
          }
        }
        &.is-language {
          @media #{$breakpoint}{
            display: block; 
          }
        }
      }
      &_child {
        display: inline-block;
        // transform: matrix(1, 0, 0, 1, 0, 0);
        // text-shadow:0px -2.5rem;
        margin: 0 -.2rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: relative;
        // color: rgb(22,50,165);
        background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        &::after {
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // top: -2.5rem;
          top: -1.3em;
          position: absolute;
          display: block;
          // color: rgb(22,50,165);
          background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
          background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
          background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
        }
        &.is-white {
          background-image: -moz-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
          background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
          background-image: -ms-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
          &::after {
            background-image: -moz-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
            background-image: -webkit-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
            background-image: -ms-linear-gradient( 90deg, rgb(255,255,255) 0%, rgb(255,255,255)  100%);
          }
        }
        &.is-back {
          -webkit-text-fill-color:#1564c5;
          background-image: none;
          // background-image: -moz-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
          // background-image: -webkit-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
          // background-image: -ms-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
          text-shadow: 0 -1.3em;
          // padding: 0;
          // transform: translateX(2.5rem);
          &::after {
            // top: 0;
            // left: 2.5rem;
            // background-image: -moz-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(21,100,197) 0%, rgb(21,100,197)  100%);
            display: none;
          }
        }
        &.is-shadow {
          line-height: 1.5;
          padding: 0;
          text-shadow: 0 -1.3em;
          // text-shadow: 0 -2.5rem;
          @include NotoSansBold;
          -webkit-text-fill-color:#222222;
          background-image: none;
          // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          &::after {
            display: none;
          }
        } 
        &.is-en { 
          &_black {
            display: inline-block;
            line-height: 1;
            // margin: 0 -.3rem;
            margin: 0 -.25rem;
            // padding-left: 0;
            // text-shadow: 0 -2.5rem;
            text-shadow: 0 -1.3em;
            @include MontoBold;
            -webkit-text-fill-color:#222222;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
          &_white {
            display: inline-block;
            line-height: 1;
            // margin: 0 -.3rem;
            margin: 0 -.25rem;
            // padding-left: 0;
            text-shadow: 0 -1.3em;
            // text-shadow: 0 -2.5rem;
            @include MontoBold;
            -webkit-text-fill-color:#ffffff;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
          &_contact_btn {
            display: inline-block;
            line-height: 1;
            margin: 0 -.4rem;
            // padding-left: 0;
            text-shadow: 0 -1.3em;
            @include MontoBold;
            -webkit-text-fill-color:#ffffff;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
          
          &_gray {
            display: inline-block;
            line-height: 1;
            margin: 0 -.13rem;
            // padding-left: 0;
            text-shadow: 0 -1.3em;
            @include Monto;
            -webkit-text-fill-color:#666666;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
        } 
        &.is-jp { 
          &_gray {
            display: inline-block;
            line-height: 1;
            margin: 0 -.3rem;
            // padding-left: 0;
            text-shadow: 0 -1.3em;
            // @include MontoBold;
            -webkit-text-fill-color:#666666;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
          &_white {
            display: inline-block;
            line-height: 1;
            margin: 0 -.3rem;
            // padding-left: 0;
            text-shadow: 0 -1.3em;
            @include NotoSansBold;
            -webkit-text-fill-color:#ffffff;
            // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
            background-image: none;
            &::after {
              display: none;
            }
          }
        } 
        &.is-select {
          display: inline-block;
          line-height: 1;
          margin: 0 -.3rem;
          // padding-left: 0;
          text-shadow: 0 0;
          font-weight: 400;
          -webkit-text-fill-color:#888888;
          // background-image: -moz-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          // background-image: -webkit-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          // background-image: -ms-linear-gradient( 90deg, rgb(34,34,34) 0%, rgb(34,34,34)  100%);
          background-image: none;
          &::after {
            display: none;
          }
        }
        &.is-back_archive {
          margin: 0 -.4rem;
          &::after {
            top: -1.3em;
          }
          &:nth-of-type(1) {
            padding-left: .2rem;
            &::after {
              content: "一";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "覧";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "へ";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "戻";
            }
          }
          &:nth-of-type(5) {
            &::after {
              content: "る";
            }
          }
        }
        &.is-back_top {
          margin: 0 -.4rem;
          &::after {
            top: -1.3em;
          }
          &:nth-of-type(1) {
            padding-left: .2rem;
            &::after {
              content: "ト";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "ッ";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "プ";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "ペ";
            }
          }
          &:nth-of-type(5) {
            &::after {
              content: "ー";
            }
          }
          &:nth-of-type(6) {
            &::after {
              content: "ジ";
            }
          }
          &:nth-of-type(7) {
            &::after {
              content: "へ";
            }
          }
        }
        &.is-about {
          position: relative;
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "A";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "b";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "o";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "u";
            }
          }
          &:nth-of-type(5) {
            &::after {
              content: "t";
            }
          }
          &:nth-of-type(6) {
            &::after {
              content: "u";
            }
          }
          &:nth-of-type(7) {
            &::after {
              content: "s";
            }
          }
        }
        &.is-faq {
          position: relative;
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "F";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "A";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "Q";
            }
          }
        }
        &.is-flow {
          position: relative;
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "F";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "l";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "o";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "w";
            }
          }
        }
        &.is-news {
          position: relative;
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "N";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "e";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "w";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "s";
            }
          }
        }
        &.is-ir {
          position: relative;
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "I";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "R";
            }
          }
          &:nth-of-type(3) {
            &::after {
              content: "（";
            }
          }
          &:nth-of-type(4) {
            &::after {
              content: "電";
            }
          }
          &:nth-of-type(5) {
            &::after {
              content: "子";
            }
          }
          &:nth-of-type(6) {
            &::after {
              content: "公";
            }
          }
          &:nth-of-type(7) {
            &::after {
              content: "告";
            }
          }
          &:nth-of-type(8) {
            &::after {
              content: "）";
            }
          }
        }
        &.is-language_en {
          &::after {
            top: -1.3em;
            @media #{$breakpointtab}{
              display: block; 
              -webkit-text-fill-color: #222222;
            }
          }
          &:nth-of-type(1) {
            padding-left: .2em;
            &::after {
              content: "E";
            }
          }
          &:nth-of-type(2) {
            &::after {
              content: "N";
            }
          }
          
        }
        &:nth-of-type(1) {
          padding-left: .2rem;
          &::after {
            content: "V";
          }
        }
        &:nth-of-type(2) {
          &::after {
            content: "i";
          }
        }
        &:nth-of-type(3) {
          &::after {
            content: "e";
          }
        }
        &:nth-of-type(4) {
          &::after {
            content: "w";
          }
        }
        &:nth-of-type(5) {
          &::after {
            content: "m";
          }
        }
        &:nth-of-type(6) {
          &::after {
            content: "o";
          }
        }
        &:nth-of-type(7) {
          &::after {
            content: "r";
          }
        }
        &:nth-of-type(8) {
          &::after {
            content: "e";
          }
        }
      } 
    }
  }
}



.c-hover {
  &_opacity {
    transition: opacity 0.4s;
    opacity: 1;

    &:hover {
      opacity: 0.6;
      @media #{$breakpoint}{
        opacity: 1;
      }
    }
  }
  &_scale {
    overflow: hidden;
    transition: opacity 0.4s,transform 0.4s;
    opacity: 1;
    &:hover {
      opacity: 0.6;
      .c-hover_img {
        transform: scale(1.1);
        @media #{$breakpoint}{
          transform: scale(1); 
        }
      }
    }
    &_txt {
      &_blue {
        overflow: hidden;
        transition: color 0.4s,transform 0.4s;
        &:hover {
          .c-hover_img {
            transform:translate(-50%,-50%) scale(1.1);
            @media #{$breakpoint}{
              transform:translate(-50%,-50%) scale(1); 
            }
          }
          .c-hover_scale_txt_blue {
            color: #1564c5;
            @media #{$breakpoint}{
              color: #222222;
            }
          }
        }
      }
    }
  }
  &_img {
    transition: transform 0.4s;
    &:hover {
      .p-list_flex_photo {
        transform: scale(1.1);
        @media #{$breakpoint}{
          transform: scale(1); 
        }
      }
    }
    &_wrapper {
      overflow: hidden;
    }
  }
  &_txt {
    &_blue {
      color: #222222;
      transition: border-bottom .4s,color .4s;
      &:hover {
        color: #1564c5;
        @media #{$breakpoint}{
          color: #222222;
        }
      }
    }
    &_black {
      color: #1564c5;
      transition: border-bottom .4s,color .4s;
      &:hover {
        color: #222222;
        @media #{$breakpoint}{
          color: #1564c5;
        }
      }
    }
  }
  &_border {
    &_blue {
      border-bottom: 1px solid #222222;
      &:hover {
        border-bottom: 0px solid #ffffff;
        @media #{$breakpoint}{
          border-bottom: 1px solid #222222;
        }
      }
    }
    &_black {
      border-bottom: 1px solid #1564c5;
      &:hover {
        border-bottom: 0px solid #ffffff;
        @media #{$breakpoint}{
          border-bottom: 1px solid #1564c5;
        }
      }
    }
  }
}


