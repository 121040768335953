@mixin lhCrop($line-height) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
  }
}

@mixin wrapper($wrapper) {
  width: $wrapper;
  margin: 0 auto;
  @media #{$breakpoint}{
    width: 100%;
  }
}

@mixin contentWidth {
  max-width: 124rem;
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 0 auto;
  @media #{$breakpoint}{
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@mixin elementImg {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  visibility: initial;
  content: '';
  position: absolute;
}

@mixin elementImgCenter {
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  visibility: initial;
  content: '';
}

@mixin contentBorder {
  content: '';
  display: block;
  width: 100%;
}

@mixin colorBlack {
  color: #222222;
}

@mixin tagBlue {
  background-image: -moz-linear-gradient( 90deg, #1632a5 50%, #2bb8fd 100%);
  background-image: -webkit-linear-gradient(90deg, #1632a5 50%, #2bb8fd 100%);
  background-image: -ms-linear-gradient( 90deg, #1632a5 50%, #2bb8fd 100%);
}
@mixin gradationBlue {
  background-image: -moz-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
  background-image: -webkit-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
  background-image: -ms-linear-gradient( 90deg, rgb(22,50,165) 13%, rgb(43,184,253) 100%);
}
@mixin gradationDarkBlue {
  background-image: -webkit-linear-gradient( -90deg,#d3e8f9 25%, #dfebf9 40%,#dfe3f2 50%, #dee0ef 100%);
}

@mixin barlowItalic {
  font-family: $fontBarlow;
  font-weight: 700;
}

@mixin barlowBold {
  font-family: $fontBarlow;
  font-weight: 600;
  letter-spacing: .06em;
}

@mixin HiraginoW3 {
  font-family: $fontHraginoW3;
  letter-spacing: .06em;
}

@mixin HiraginoW6 {
  font-family: $fontHraginoW6;
  font-weight: bold;
  letter-spacing: .06em;
}

@mixin NotoSans {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
}
@mixin NotoSansBold {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
}
@mixin NotoSerif {
  font-family: 'Noto Serif JP', serif;
  font-weight: 500;
}
@mixin Jomo {
  font-family: 'Jomolhari', serif;
  font-weight: 400;
  letter-spacing: .08em;
}
@mixin Monto {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  letter-spacing: 0;
}
@mixin MontoItal {
  font-family: 'Montserrat', serif;
  font-weight: 500;
  letter-spacing: 0;
  font-style: italic;
}
@mixin MontoBold {
  font-family: 'Montserrat', serif;
  font-weight: 700;
  letter-spacing: 0;
}
@mixin MontoBoldItal {
  font-family: 'Montserrat', serif;
  font-weight: 700;
  letter-spacing: 0;
  font-style: italic;
}

